import { useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { localeI18nContext } from "../context";

const iconStyle = {
  width: 36,
  height: 36,
};

export function ContactMenu() {
  const localeI18n = useContext(localeI18nContext);
  return (
    <Box
      sx={{
        placeSelf: "center",
        display: "grid",
        gridTemplateColumns: { xs: "repeat(2, auto)", sm: "repeat(6, auto)" },
        justifyContent: "space-around",
        alignItems: "center",
        width: { xs: "320px", sm: "auto" },
        gap: 2,
        marginY: 4,
      }}
    >
      {[
        {
          icon: <HomeIcon sx={iconStyle} />,
          title: localeI18n.homeTitle,
          href: localeI18n.home,
        },
        {
          icon: <LibraryBooksIcon sx={iconStyle} />,
          title: localeI18n.blogTitle,
          href: localeI18n.blog,
        },
        {
          icon: <LocationCityIcon sx={iconStyle} />,
          title: localeI18n.locationTitle,
          href: localeI18n.location,
        },
        {
          icon: <EmailIcon sx={iconStyle} />,
          title: localeI18n.emailTitle,
          href: localeI18n.email,
        },
        {
          icon: <GitHubIcon sx={iconStyle} />,
          title: localeI18n.githubTitle,
          href: localeI18n.github,
        },
        {
          icon: <LinkedInIcon sx={iconStyle} />,
          title: localeI18n.linkedInTitle,
          href: localeI18n.linkedIn,
        },
      ].map((item) => (
        <Box
          key={item.title}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title={item.title} placement="top">
            <IconButton
              color="inherit"
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
              href={item.href}
              target={item.title === localeI18n.emailTitle ? "_self" : "_blank"}
            >
              {item.icon}
            </IconButton>
          </Tooltip>
          <Typography
            sx={{ fontWeight: "bold", display: { xs: "block", sm: "none" } }}
            variant="body2"
            component={Link}
            underline="none"
            color="inherit"
            href={
              item.title === localeI18n.emailTitle
                ? `mailto:${item.href}`
                : item.href
            }
            target={item.title === localeI18n.emailTitle ? "_self" : "_blank"}
          >
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
