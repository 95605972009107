import { useContext } from "react";
import Box from "@mui/material/Box";

import { DemoCard } from "../components/DemoCard";

import { localeI18nContext } from "../context";

export function DemoModule() {
  const localeI18n = useContext(localeI18nContext);
  return (
    <Box
      id="demo"
      sx={{
        width: "100vw",
      }}
    >
      {localeI18n.demo.map((item, index) => {
        return <DemoCard key={item.title} index={index} {...item} />;
      })}
    </Box>
  );
}
