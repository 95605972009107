import { Dispatch, SetStateAction } from "react";

import Box from "@mui/material/Box";
import {
  LandingBackground,
  LandingContent,
  LocaleButton,
} from "../components";

import { locale } from "../configs";

export function LandingModule({
  locale,
  setLocale,
}: {
  locale: locale;
  setLocale: Dispatch<SetStateAction<locale>>;
}) {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "grid",
        // alignContent: "space-evenly",
      }}
    >
      <LandingBackground />
      <LocaleButton locale={locale} setLocale={setLocale} />
      <LandingContent />
    </Box>
  );
}
