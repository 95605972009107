import Typography from "@mui/material/Typography";

export function SiteFooter() {
  return (
    <Typography
      sx={{
        color: theme => theme.palette.grey["200"],
        textAlign: "center",
        marginBottom: 1,
      }}
      variant="body2"
    >
      Copyright &copy; 2018-{new Date().getFullYear()} w00d1f.com
    </Typography>
  );
}
