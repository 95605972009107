import { useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ContactMenu, SiteFooter } from "../components";
import { localeI18nContext } from "../context";

export function ContactModule() {
  const localeI18n = useContext(localeI18nContext);
  return (
    <Box
      id="contact"
      sx={{
        width: "100vw",
        display: "grid",
        paddingY: 2,
        borderTop: "4px solid #e44c65",
        backgroundImage: "linear-gradient(to top, #09203f 0%, #348cb2 150%)",
        color: theme => theme.palette.grey["200"],
      }}
      component="footer"
    >
      <Box
        sx={{
          marginLeft: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#e44c65",
            width: 4,
            height: 20,
            marginRight: 2,
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {localeI18n.contactTitle}
        </Typography>
      </Box>
      <ContactMenu />
      <SiteFooter />
    </Box>
  );
}
