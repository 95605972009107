/**
 * 根据动画参数变化范围与滚动位置变化范围，及当前滚动位置，计算出当前的动画的参数（为方便计算和理解，滚动位置以整屏数计算，即100vh为1单位的整屏高度）
 * @param {
 *  animateStart: number;
 *  animateEnd: number;
 *  currentScroll: number;
 *  scrollStart: number;
 *  scrollEnd: number;
 * } 动画起始的参数，动画结束的参数，当前滚动到的整屏数，动画开始的滚动位置整屏数，动画结束的滚动位置整屏数
 * @returns 滚动过程中计算出的动画参数
 */
export function calculateLinearAnimation({
  animateStart,
  animateEnd,
  currentScroll,
  scrollStart,
  scrollEnd,
}: {
  animateStart: number;
  animateEnd: number;
  currentScroll: number;
  scrollStart: number;
  scrollEnd: number;
}) {
  if (currentScroll < scrollStart) {
    return animateStart;
  } else if (
    currentScroll >= scrollStart &&
    currentScroll < scrollEnd &&
    animateEnd !== animateStart
  ) {
    return (
      animateStart +
      (animateEnd - animateStart) *
        ((currentScroll - scrollStart) / (scrollEnd - scrollStart))
    );
  } else {
    return animateEnd;
  }
}
