import { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Diversity2Icon from "@mui/icons-material/Diversity2";

import { localeI18nContext } from "../context";
import { openFrame, fadeFrame } from "../styles";

export function LandingContent() {
  const localeI18n = useContext(localeI18nContext);
  const theme = useTheme();
  const isOverSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        placeSelf: "center",
        transform: "translateY(-10%)",
        color: theme => theme.palette.grey["200"],
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr)",
        gridTemplateRows: "auto 2rem auto 2rem auto",
        opacity: 0,
        animation: `1s 2s forwards ${fadeFrame(1)}`,
      }}
      component="header"
    >
      <Box
        sx={{
          placeSelf: "center",
          display: "grid",
          width: 60,
          height: 60,
          border: "1px solid #fff",
          borderRadius: "50%",
        }}
      >
        <Diversity2Icon
          sx={{
            placeSelf: "center",
          }}
        />
      </Box>
      <Box
        sx={{
          placeSelf: "center",
          width: "1px",
          height: 1,
          background: "#fff",
        }}
      ></Box>
      <Box
        sx={{
          placeSelf: "center",
          display: "grid",
          placeItems: "flex-start center",
          width: { xs: "90vw", md: "55vw" },
          borderTop: "1px solid #fff",
          borderBottom: "1px solid #fff",
          overflow: "hidden",
          padding: "0 1rem",
          maxHeight: 0,
          animation: `1s ease-in-out 2.5s forwards ${openFrame}`,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h3"
        >
          {localeI18n.title}
        </Typography>
        <Typography
          sx={{
            opacity: 0.85,
            marginTop: "1rem",
            textAlign: "center",
          }}
          variant="subtitle1"
        >
          {localeI18n.subTitle}
        </Typography>
        <Typography
          sx={{
            opacity: 0.85,
            marginTop: "0.5rem",
            textAlign: "center",
          }}
          variant="subtitle1"
        >
          {localeI18n.description}
        </Typography>
      </Box>
      <Box
        sx={{
          placeSelf: "center",
          width: "1px",
          height: 1,
          background: "#fff",
        }}
      ></Box>
      <ButtonGroup
        sx={{
          placeSelf: "center",
        }}
        color="inherit"
        variant="outlined"
        orientation={isOverSmall ? "horizontal" : "vertical"}
        aria-label="outlined button group"
      >
        <Button
          sx={{
            width: 120,
          }}
          href="#intro"
        >
          {localeI18n.menu1}
        </Button>
        <Button
          sx={{
            width: 120,
          }}
          href="#demo"
        >
          {localeI18n.menu2}
        </Button>
        <Button
          sx={{
            width: 120,
          }}
          href={localeI18n.blog}
          target="_blank"
        >
          {localeI18n.menu3}
        </Button>
        <Button
          sx={{
            width: 120,
          }}
          href="#contact"
        >
          {localeI18n.menu4}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
