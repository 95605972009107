const startWorkingYear = "2018-01-01";
export function calculateYears(): number {
  const startDate: Date = new Date(startWorkingYear); // set the start date to January 1, 2018
  const currentDate: Date = new Date(); // set the end date to the current date and time
  const millisecondsPerYear: number = 1000 * 60 * 60 * 24 * 365.25; // calculate the number of milliseconds per year, accounting for leap years

  const diffInMilliseconds: number =
    currentDate.getTime() - startDate.getTime(); // calculate the difference between the two dates in milliseconds
  const years: number = Math.floor(diffInMilliseconds / millisecondsPerYear); // divide the difference by the number of milliseconds per year and round down to get the number of years

  return years; // return the number of years
}

export function calculateYearsChinese(): string {
  const startDate: Date = new Date(startWorkingYear); // set the start date to January 1, 2018
  const currentDate: Date = new Date(); // set the end date to the current date and time
  const millisecondsPerYear: number = 1000 * 60 * 60 * 24 * 365.25; // calculate the number of milliseconds per year, accounting for leap years

  const diffInMilliseconds: number =
    currentDate.getTime() - startDate.getTime(); // calculate the difference between the two dates in milliseconds
  const years: number = Math.floor(diffInMilliseconds / millisecondsPerYear); // divide the difference by the number of milliseconds per year and round down to get the number of years

  const chineseNumerals: string[] = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
  ];
  const chineseUnits: string[] = ["", "十", "百", "千"];
  let chineseYears: string = "";

  if (years === 0) {
    chineseYears = chineseNumerals[0];
  } else {
    let numDigits: number = Math.floor(Math.log10(years)) + 1; // get the number of digits in the year

    for (let i = 0; i < numDigits; i++) {
      let digit: number = Math.floor(years / Math.pow(10, i)) % 10; // get the i-th digit of the year
      let unit: string = chineseUnits[numDigits - i - 1]; // get the Chinese unit for the i-th digit
      let numeral: string = chineseNumerals[digit]; // get the Chinese numeral for the i-th digit

      // skip the digit and unit if the digit is 0 and the unit is not the last one
      if (digit === 0 && i !== numDigits - 1) {
        continue;
      }

      chineseYears += numeral + unit; // concatenate the Chinese numeral and unit for the i-th digit
    }
  }

  chineseYears += "年"; // add the Chinese character for "year" to the end of the string
  return chineseYears; // return the Chinese numeral for the number of years
}
