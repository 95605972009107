import { keyframes } from "@emotion/react";

export const fadeFrame = (opacity: number) => keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: ${opacity};
}
`;
export const bgFrame = keyframes`
0% {
  transform: translate3d(0,0,0);
}

100% {
  transform: translate3d(-150vw,0,0);
}
`;
export const openFrame = keyframes`
0% {
  max-height: 0;
  padding: 0 1rem;
}

100% {
  max-height: 300px;
  padding: 2rem 1rem;
}
`;
