import { useState } from "react";
import { locale } from "../configs";

export function useLocale() {
  const localeFromStorage = localStorage.getItem("locale");
  const localeTemp: locale | null = localeFromStorage
    ? JSON.parse(localeFromStorage)
    : null;

  return useState<locale>(localeTemp ?? "en");
}
