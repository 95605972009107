import Box from "@mui/material/Box";

import bgImg from "../assets/images/bg.png";
import overlayImg from "../assets/images/overlay.svg";

import { fadeFrame, bgFrame } from "../styles";

export function LandingBackground() {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "300vw",
          height: 1,
          backgroundImage: `url(${bgImg}), linear-gradient(to bottom, #09203f 0%, #348cb2 100%)`,
          backgroundPosition: "bottom left",
          backgroundRepeat: "repeat-x",
          backgroundSize: {
            xs: "150vw auto",
            sm: "75vw auto",
            md: "50vw auto",
          },
          animation: `60s linear infinite ${bgFrame}`,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          backgroundImage: `url(${overlayImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: 0,
          animation: `1s 1s forwards ${fadeFrame(0.5)} `,
          backgroundAttachment: "fixed",
        }}
      ></Box>
    </>
  );
}
