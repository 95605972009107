import { useRef, useContext, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import bgImgMoonSky from "../assets/images/demo-bg-moon-sky.jpg";
import bgImgEarth from "../assets/images/demo-bg-earth.jpg";
import bgImgMoonShadow from "../assets/images/demo-bg-moon-shadow.jpg";
import bgImgNight from "../assets/images/demo-bg-night.jpg";

import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import { localeI18nContext } from "../context";

const bgList = [bgImgMoonSky, bgImgEarth, bgImgMoonShadow, bgImgNight];

export function DemoCard({
  title,
  content,
  link,
  index,
}: {
  title: string;
  content: string;
  link: string;
  index: number;
}) {
  const localeI18n = useContext(localeI18nContext);
  const containerRef = useRef<HTMLElement>(null);

  const [showDrawer, setShowDrawer] = useState(false);
  const intersectionCallback = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      if (entry.intersectionRatio >= 0.5) {
        setShowDrawer(true);
      } else {
        setShowDrawer(false);
      }
    },
    []
  );
  // 与视窗口相交超过 50% 时触发
  const intersectionOptions = { threshold: 0.5 };
  useIntersectionObserver(
    containerRef,
    intersectionCallback,
    intersectionOptions
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100vh",
        display: "grid",
        gridTemplate: {
          xs: `"drawer" 1fr
        "none" 0 / minmax(0, 1fr)`,
          md: [
            `"drawer" 2fr
          "none" minmax(0, 3fr) / minmax(0, 1fr)`,
            `"drawer none" minmax(0, 1fr) / 1.5fr minmax(0, 2fr)`,
            `"none drawer" minmax(0, 1fr) / minmax(0, 2fr) 1.5fr`,
          ][index % 3],
          lg: [
            `"drawer" 2fr
          "none" minmax(0, 3fr) / minmax(0, 1fr)`,
            `"drawer none" minmax(0, 1fr) / 1fr minmax(0, 2fr)`,
            `"none drawer" minmax(0, 1fr) / minmax(0, 2fr) 1fr`,
          ][index % 3],
        },
        background: `url(${bgList[index % 4]})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          gridArea: "none",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        }}
      ></Box>
      <Box
        sx={{
          gridArea: "drawer",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        }}
      >
        <Slide
          container={containerRef.current}
          in={showDrawer}
          direction={
            (["down", "right", "left"] as ("down" | "left" | "right")[])[
              index % 3
            ]
          }
          timeout={600}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              backgroundImage: `linear-gradient(to ${
                ["bottom", "right", "left"][index % 3]
              }, rgba(23, 24, 32, 1) 0%, #09203f 100%)`,
              borderStyle: "solid",
              borderWidth: ["0 0 4px 0", "0 4px 0 0", "0 0 0 4px"][index % 3],
              borderColor: ["#5480f1", "#39c088", "#e44c65"][index % 3],
              boxShadow: (theme) => theme.shadows[24],
              display: "grid",
              gridTemplate: "repeat(3, auto) / minmax(0, 1fr)",
              gap: { xs: 4, md: index % 3 === 0 ? 4 : 2 },
              placeContent: { xs: "center", md: "space-evenly" },
              justifyItems: {
                xs: "flex-start",
                md: index % 3 === 0 ? "center" : "flex-start",
              },
              color: (theme) => theme.palette.grey["200"],
              padding: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                maxWidth: "80vw",
                lineHeight: 1.5,
              }}
              variant="body1"
            >
              {content}
            </Typography>
            <Button
              color="inherit"
              variant="outlined"
              href={link}
              target="_blank"
            >
              {localeI18n.demoLinkButton}
            </Button>
          </Box>
        </Slide>
      </Box>
    </Box>
  );
}
