import { useCallback, Dispatch, SetStateAction } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TranslateIcon from "@mui/icons-material/Translate";

import { locale } from "../configs";
import { fadeFrame } from "../styles";

export function LocaleButton({
  locale,
  setLocale,
}: {
  locale: locale;
  setLocale: Dispatch<SetStateAction<locale>>;
}) {
  const changeLocale = useCallback(() => {
    if (locale === "en") {
      setLocale("zh");
      localStorage.setItem("locale", JSON.stringify("zh"));
    } else {
      setLocale("en");
      localStorage.setItem("locale", JSON.stringify("en"));
    }
  }, [setLocale, locale]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: 20,
        right: 20,
        color: theme => theme.palette.grey["200"],
        opacity: 0,
        animation: `1s 2s forwards ${fadeFrame(1)}`,
      }}
    >
      <IconButton color="inherit" onClick={changeLocale}>
        <TranslateIcon />
      </IconButton>
    </Box>
  );
}
