import { useMemo } from "react";

import Box from "@mui/material/Box";

import { internationalization } from "./configs";
import { localeI18nContext } from "./context";

import {
  IntroductionModule,
  LandingModule,
  ContactModule,
  DemoModule,
} from "./modules";
import { fadeFrame } from "./styles/keyframes";
import { useLocale } from "./hooks";

import "./App.scss";

function App() {
  const [locale, setLocale] = useLocale();
  const localeI18n = useMemo(() => {
    return internationalization[locale];
  }, [locale]);

  return (
    <localeI18nContext.Provider value={localeI18n}>
      <Box
        sx={{
          animation: `2s forwards ${fadeFrame(1)}`,
          opacity: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <LandingModule locale={locale} setLocale={setLocale} />
        <IntroductionModule />
        <DemoModule />
        <ContactModule />
      </Box>
    </localeI18nContext.Provider>
  );
}

export default App;
