import { calculateYears, calculateYearsChinese } from "../utils";

export type locale = "en" | "zh";

const contact = {
  home: "https://w00d1f.com",
  blog: "https://blog.w00d1f.com",
  location: "https://ditu.amap.com/search?query=%E5%B9%BF%E5%B7%9E%E5%B8%82",
  email: "wonderlust91@w00d1f.com",
  githubTitle: "GitHub",
  github: "https://github.com/WONDERlust91",
  linkedIn: "https://www.linkedin.com/in/tom-wu-8b686826a",
};

export const internationalization = {
  zh: {
    title: "w00d1f",
    subTitle: "前端工程师 • 系统规划与管理师（高级）",
    description: "求索，精进",
    menu1: "个人简介",
    menu2: "工作样例",
    menu3: "技术博客",
    menu4: "与我联系",
    introduction: [
      {
        title: "关于我",
        content: `${calculateYearsChinese()}经验前端研发工程师 • 系统规划与管理师（高级职称）。热爱技术，热爱分享，注重“软实力”的培养，有良好的表达能力，重视协作与沟通。坚持技术博客写作，坚持跑步、健身，想探索更多，期望做到更好！`,
      },
      {
        title: "技术栈",
        content: `熟悉：React、Vue框架；\n熟悉：Typescript；\n熟悉：HTTP协议；\n熟悉：Apollo Client与GraphQL；\n熟悉：Material UI、AntDesign、iViewDesign；\n熟悉：Cesium、Leaflet、Openlayers；\n熟悉：微信小程序；\n了解：Rust、Python；`,
      },
      {
        title: "软技能",
        content:
          "分享，协作，沟通是我很重视的三个方面。将个人积累到的知识以技术分享会的形式在团队中分享，既巩固了知识又提升了团队，实现双赢，并且技术分享的团队文化可以引导良性的工作氛围，明显增进同事间的关系，对协作与沟通也有所助益。协作与沟通也是技术人常常容易忽视的一点，与同事间良好的沟通与协作，在我的工作中常常起到事半功倍的效果。",
      },
      {
        title: "业余提升",
        content:
          "坚持跑步、健身，健康的体魄是高效工作的有力保证。业余时间也会对工作中发现的薄弱点或新知识，查阅资料并撰写技术博客，坚持原创，不直接抄写资料是我技术提升的小诀窍。英文是查阅资料文档的最佳助益，目前每天会花一些时间学习，未来为加大对英文学习的投入。",
      },
    ],
    demo: [
      {
        title: "源场景云平台",
        content:
          "基于React、Material UI、GraphQL、Cesium等技术，建立的古建数字资产云平台，PC端与手机端全端适配，一个平台多个项目可复用，可视化零代码配置。",
        link: "http://yun.yuancj.com",
      },
      {
        title: "React-AntD脚手架",
        content:
          "基于CRA、Typescript、AntDesign、React-Router、Redux Toolkit技术搭建的脚手架项目，集成了登录、注册、忘记密码、重置密码、修改密码等组件，具有前端权限判断功能，并依托脚手架，展示了两个小组件。1. 可滚动按钮时间轴播放器组件，气象行业常用组件，根据选中的时间轴不同刻度，获取不同的气象数据，并展示于地图界面中；2. 七日天气小组件，使用格式化的数据，快速生成7日天气效果。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/react-antd-components",
      },
      {
        title: "表单设计器",
        content:
          "基于Vue-CLI、iViewUI库、Vue-Draggable拖拽库制作的表单设计工具，拖拽操作，布局可嵌套，配置结果可导入导出JSON，连接后端后可实现可视化建表。完全体组件除表单设计功能外，还能管理后端数据表，并使用Table、Form等组件使用后端数据表，实现了全流程无代码可视化操作。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/form-builder",
      },
      {
        title: "时间轴播放器",
        content:
          "气象常用组件，播放一个时间列表，返回当前选中时间，可调节播放速度，展示数据到达情况，通过主轴分类实况数据与预报数据。该组件使用原生HTML及Typescript制作，未耦合前端框架，适配范围广。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/timeline-player",
      },
      {
        title: "图例组件",
        content:
          "气象常用组件，在WebGIS中根据气象要素，显示图例，图例可垂直可水平放置，图例数字支持居中与卡尺两种形式。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/legend-demo",
      },
      {
        title: "Vue-iView脚手架",
        content:
          "以Vue为主体前端框架，使用iViewDesign作为UI库，搭建为简洁的后台管理系统模板脚手架，实现框架通用，业务聚焦的敏捷开发需求。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/admin",
      },
      {
        title: "Cesium演示系统",
        content:
          "安监智慧一张图GIS演示系统，3D使用Cesium，2D使用Openlayers技术，前端框架使用Vue，系统包括点位图层信息展示，态势标绘，污染溯源动画，水环境预测预警，报警监控等功能。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/gis",
      },
      {
        title: "YLeaflet格点组件",
        content:
          "气象核心组件，可用于绘制格点色斑图，等值面，风杆，台风等气象数据可视化。这里展示的是风杆数据的可视化。（Demo项目仅适配FHD）",
        link: "https://demo.w00d1f.com/yleaflet",
      },
    ],
    demoLinkButton: "查看示例",
    contactTitle: "与我联系",
    homeTitle: "主页",
    blogTitle: "博客",
    locationTitle: "广州",
    emailTitle: "邮件",
    linkedInTitle: "领英",
    ...contact,
  },
  en: {
    title: "w00d1f",
    subTitle:
      "Front-end developer • System planning and management engineer (Senior)",
    description: "Wonder more, strive to do better",
    menu1: "Intro",
    menu2: "Demo",
    menu3: "Blog",
    menu4: "Contact",
    introduction: [
      {
        title: "About me",
        content: `Front-end developer with ${calculateYears()} years experience. Certified senior engineer in system planning and management. I love the IT field, and enjoy sharing my knowledge and focusing on developing my soft skills. I possess strong communication skills and believe that cooperation and communication are essential in any work environment. Additionally, I insist on writing tech blogs and prioritize running and exercising regularly. I am always curious and eager to learn more, and continuously strive to improve.`,
      },
      {
        title: "Technology Stacks",
        content: `I am proficient in React and Vue frameworks, as well as TypeScript, HTTP protocol, Apollo Client with GraphQL, and UI libraries such as Material UI, AntDesign, and iViewDesign. Familiar with geospatial libraries such as Cesium, Leaflet, and OpenLayers. Experienced in developing WeChat Mini Programs and has knowledge of Rust and Python.`,
      },
      {
        title: "Soft skills",
        content:
          "Sharing, cooperation, and communication are three important skills for me. I always share the new knowledge that I gain from work during tech sharing meetings. This not only enhances my own understanding of the knowledge but also improves our team's skills, creating a win-win situation. Tech sharing can also lead to a better work atmosphere and improved colleague relationships. Good communication and cooperation are often overlooked skills in the tech field, but they have helped me become much more efficient in my work.",
      },
      {
        title: "Personal development",
        content:
          "I insist on running and doing fitness because I believe that having a healthy body is the foundation of working efficiently. During my free time, I often search for new information and write tech blogs about topics that I find challenging or unfamiliar in my work. To improve my skills, I write the blog in my own words instead of copying from the information source. I also recognize that English is a valuable tool for searching information, so I spend some time every day learning and practicing it, and I plan to increase my English language input in the future.",
      },
    ],
    demo: [
      {
        title: "Source Scene Cloud Platform",
        content:
          "A digital asset cloud platform for ancient buildings, built with technologies such as React, Material UI, GraphQL, and Cesium. It is fully adapted for both PC and mobile devices, with one platform supporting multiple projects that can be reused with zero-code configuration.",
        link: "http://yun.yuancj.com/",
      },
      {
        title: "React-AntD Scaffold",
        content:
          "A scaffold project built with technologies such as CRA, Typescript, AntDesign, React-Router, and Redux Toolkit. It integrates login, registration, forget password, reset password, and modify password components, with frontend authorization functionality. Based on the scaffold, it also showcases two small components. 1. A scrollable button timeline player component, commonly used in the meteorological industry. It retrieves different meteorological data based on the selected timeline scale and displays it on the map interface. 2. A seven-day weather small component that generates a 7-day weather effect using formatted data. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/react-antd-components/",
      },
      {
        title: "Form Builder",
        content:
          "A form design tool created with Vue-CLI, iViewUI library, and Vue-Draggable drag-and-drop library. It supports drag-and-drop operation, nested layout, and the configuration result can be imported and exported as JSON. When connected to the backend, it can achieve visual table creation. In addition to the form design function, the complete component can also manage the backend data table and use components such as Table and Form to operate on backend data tables, achieving a full-process zero-code visual operation. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/form-builder/",
      },
      {
        title: "Timeline Player",
        content:
          "A meteorological commonly used component that plays a time list and returns the currently selected time. It can adjust the playback speed, display the arrival of data, and classify the real-time data and forecast data by the main axis. This component is created using native HTML and Typescript, without coupling with frontend frameworks, and has a wide range of adaptability. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/timeline-player/",
      },
      {
        title: "Legend Component",
        content:
          "A meteorological commonly used component that displays a legend in WebGIS according to meteorological elements. The legend can be placed vertically or horizontally, and the legend number supports two formats: centered and caliper. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/legend-demo/",
      },
      {
        title: "Vue-iView Scaffold",
        content:
          "A concise backend management system template scaffold, with Vue as the main frontend framework and iViewDesign as the UI library. It achieves agile development requirements with a common framework and business focus. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/admin/",
      },
      {
        title: "Cesium Demonstration System",
        content:
          "An intelligent monitoring GIS demonstration system for safety production, with 3D using Cesium technology and 2D using Openlayers technology. The frontend framework is Vue. The system includes functions such as point layer information display, situational plotting, pollution traceability animation, water environment prediction and warning, alarm monitoring, etc. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/gis/",
      },
      {
        title: "YLeaflet Meteorological Component",
        content:
          "a core meteorological component that can be used to draw grid color patches, contour lines, wind barbs, typhoon and other meteorological data visualizations. Here, we display wind barb data visualization. (Demo project only supports FHD)",
        link: "https://demo.w00d1f.com/yleaflet/",
      },
    ],
    demoLinkButton: "View Demo",
    contactTitle: "Get In Touch",
    homeTitle: "Home",
    blogTitle: "Blog",
    locationTitle: "GuangZhou, China",
    emailTitle: "Email",
    linkedInTitle: "LinkedIn",
    ...contact,
  },
};
