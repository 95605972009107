import { useState, useEffect } from "react";
export function useScroll() {
  const [scrollInfo, setScrollInfo] = useState<{
    innerHeight: number;
    scrollY: number;
  } | null>(null);
  useEffect(() => {
    const handleScroll = () => {
      setScrollInfo({
        innerHeight: window.innerHeight,
        scrollY: window.scrollY,
      });
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollInfo;
}
