import { useMemo, useContext, useCallback, forwardRef } from "react";

import Box from "@mui/material/Box";
import Card, { CardProps } from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import { SxProps, Theme } from "@mui/material";
import backgroundImg from "../assets/images/intro-bg.jpg";

import { localeI18nContext } from "../context";
import { useScroll } from "../hooks";
import { calculateLinearAnimation } from "../utils";

// 定义当前页面共几个全屏高度
const totalView = 4;
// 定义当前页面顶部完全进入屏幕时，滚动到了第几个全屏高度
const startView = 1;
// 定义当前页面底部刚刚离开屏幕底部时，滚动到了第几个全屏高度
const endView = startView + totalView - 1;

export function IntroductionModule() {
  const localeI18n = useContext(localeI18nContext);
  const scrollInfo = useScroll();
  // 通过滚动hook计算当前滚动整屏数，即第几个100vh
  const scrollToNthView = useMemo(() => {
    if (scrollInfo) {
      return Number((scrollInfo.scrollY / scrollInfo.innerHeight).toFixed(2));
    } else {
      return 0;
    }
  }, [scrollInfo]);
  // 通过滚动整屏数，以及动画范围参数，计算滚动过程中的动画参数
  const moonShadowAnimationValue = useCallback(
    (animateStart: number) => {
      return calculateLinearAnimation({
        animateStart,
        animateEnd: 0,
        currentScroll: scrollToNthView,
        scrollStart: 1,
        scrollEnd: 4,
      });
    },
    [scrollToNthView]
  );

  return (
    <Box
      id="intro"
      sx={{
        // 设定当前模块高度为4个整屏高度
        height: `${totalView * 100}vh`,
      }}
    >
      <Box
        sx={{
          // 设置sticky并设置高度为100vh，使页面滚动时，画面不动
          height: "100vh",
          position: "sticky",
          top: 0,
          overflow: "hidden",
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            // 月亮阴影随滚动变化
            position: "absolute",
            top: 0,
            right: 0,
            width: "200vw",
            height: "200vh",
            backgroundColor: "#000",
            boxShadow: {
              xs: `0px 0px 150px ${moonShadowAnimationValue(115)}vw black`,
              sm: `0px 0px 150px ${moonShadowAnimationValue(100)}vw black`,
              md: `0px 0px 150px ${moonShadowAnimationValue(85)}vw black`,
              lg: `0px 0px 150px ${moonShadowAnimationValue(70)}vw black`,
            },
            transformOrigin: "bottom right",
            transform: {
              xs: "translate(0, -75%) rotate(60deg)",
              sm: "translate(0, -65%) rotate(60deg)",
              md: "translate(0, -70%) rotate(60deg)",
              lg: "translate(0, -50%) rotate(60deg)",
            },
            zIndex: -1,
          }}
        ></Box>
        {localeI18n.introduction.map((item, index) => {
          // 按简介数量与起止整屏数计算每个简介的动画间隔
          const interval =
            (endView - startView) / localeI18n.introduction.length;

          // 首个元素动画先滑入后消失，最后一个元素动画是先出现再滑出，其余元素根据下标奇偶不同，左右滑动
          // slide内套用fade，实现首个元素的消失，最后一个元素的出现，其余元素fade永远为true
          return (
            <Slide
              key={item.title}
              in={
                index === 0
                  ? scrollToNthView >= startView
                  : index === localeI18n.introduction.length - 1
                  ? scrollToNthView < endView
                  : scrollToNthView >= startView + index * interval &&
                    scrollToNthView < startView + (index + 1) * interval
              }
              direction={
                index === 0
                  ? "up"
                  : index === localeI18n.introduction.length - 1
                  ? "down"
                  : index % 2 === 0
                  ? "left"
                  : "right"
              }
              timeout={600}
            >
              <Fade
                in={
                  index === 0
                    ? // 这里比起始整屏数早0.5出现，为了防止滑入失效
                      scrollToNthView > startView - 0.5 &&
                      scrollToNthView < startView + interval
                    : index === localeI18n.introduction.length - 1
                    ? scrollToNthView >= startView + index * interval &&
                      // 在结束整屏数外0.5才消失，为了防止滑出效果失效
                      scrollToNthView < endView + 0.5
                    : // 其余元素fade效果不生效
                      true
                }
                timeout={600}
              >
                <CardIntroduction title={item.title} content={item.content} />
              </Fade>
            </Slide>
          );
        })}
      </Box>
    </Box>
  );
}

const cardStyle: SxProps<Theme> = {
  // grid定位居中
  gridArea: "1 / 1 / 2 / 2",
  color: (theme) => theme.palette.grey[200],
  maxWidth: { xs: "90vw", sm: "450px" },
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  backdropFilter: "blur(4px)",
};
const cardHeaderStyle = {
  sx: {
    lineHeight: 2,
    letterSpacing: 2,
  },
};
const cardContentStyle: SxProps<Theme> = {
  lineHeight: 1.5,
  whiteSpace: "pre-line",
};

interface CardIntroductionProps extends CardProps {
  title: string;
  content: string;
}
// 通过forwardRef，使CardIntroduction可以被外部transition组件包裹
const CardIntroduction = forwardRef<HTMLDivElement, CardIntroductionProps>(
  ({ title, content, ...props }, ref) => {
    return (
      <Card sx={cardStyle} ref={ref} {...props}>
        <CardHeader
          titleTypographyProps={cardHeaderStyle}
          title={title}
        ></CardHeader>
        <CardContent>
          <Typography sx={cardContentStyle} variant="body1">
            {content}
          </Typography>
        </CardContent>
      </Card>
    );
  }
);
