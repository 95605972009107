import { useEffect } from "react";

export function useIntersectionObserver(
  target: React.RefObject<HTMLElement>,
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit
) {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (target.current) {
      observer.observe(target.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [target, callback, options]);
}
